import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import { getHeight, getIsVertical } from "../utils/getIsVertical";
import { Rure } from "../components/Rure";
import { COLORS } from "../utils/consts";

import "../styles/index.css";

export default function IndexPage() {
	const [containerHeight, setContainerHeight] = React.useState("100vh");
	const isVertical = getIsVertical();

	const onResize = React.useCallback(() => {
		if (getIsVertical()) {
			setContainerHeight(`calc(${getHeight()}px - ${document.body.style.paddingBottom})`);
		}
	}, []);

    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        onResize();

        const resizeListener = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                onResize();
            }, 150);
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []);

	return (
		<Layout pageId={PageId.Index} resizeCallback={onResize}>
			<Seo
				description="Rūre ir jauna veselības un aprūpes sistēma, kas vienuviet sniedz kā visaptverošus pakalpojumus senioriem, tā augstas kvalitātes paliatīvo aprūpi"
			/>
			<div className="landingContainer" style={{ height: containerHeight }}>
				<div className="landingBGImage">
					<StaticImage
						src="../images/bgImage.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="landingBG"
						placeholder="blurred"
						objectFit="cover"
						objectPosition="50% 50%"
						style={{
							width: "100%",
							height: "100%",
						}}
					/>
				</div>
				<div className="mainRightBlock">
					<div className="rureMainImage">
						<Rure fill={isVertical ? COLORS.white : undefined} />
					</div>
					<h1 className="mainHeading">
						Tā ir vesela dzīve
					</h1>
				</div>
			</div>
		</Layout>
	);
}
